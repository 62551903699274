
import React, {Fragment} from "react";
import boLogo from "../bo_logo.jpg";
import merchiersLogo from "../merchiers_logo.jpg";
import ialLogo from "../ial_logo.png";

function Coop(props) {

    let content = {
        English: {
            title: "In cooperation with"
        },
        Deutsch: {
            title: "In Zusammenarbeit mit"
        }
    };

    props.language === "Deutsch" ? (content = content.Deutsch) : (content = content.English);


  return (
    <Fragment>
        <h2>{content.title}</h2>
        <hr />
        <div class="container">
            <div class="row">
                <div class="col-md-4 mb-3 mt-2">
                    <a href="https://www.hochschule-bochum.de"><img class="" src={boLogo} alt="www.hochschule-bochum.de" style={{width: '85%'}}></img></a>
                </div>
                <div class="col-md-4 mb-3 mt-2">
                    <a href="https://www.ial.ruhr"><img class="" src={ialLogo} alt="www.ial.ruhr" style={{width: '60%'}}></img></a>
                </div>
                <div class="col-md-4 mb-3 mt-2">
                    <a href="https://www.merchiers-consulting.de"><img class="" src={merchiersLogo} alt="www.merchiers-consulting.de" style={{width: '85%'}}></img></a>
                </div>
            </div>
        </div>
    </Fragment>
  );
}

export default Coop;