import React from "react";
import { Link, withRouter } from "react-router-dom";

function Footer(props) {
    let content = {
        English: {
            legal: "Legal notice"
        },
        Deutsch: {
            legal: "Impressum und Datenschutz"
        }
    };

    props.language === "Deutsch" ? (content = content.Deutsch) : (content = content.English);
    return (
        <div class="footer">
            <footer class="py-0 bg-dark fixed-bottom">
                <div class="container">
                    
                    <nav class="navbar navbar-expand navbar-dark bg-dark justify-content-between">
                        <div class="container">
                        <p class="text-white text-left" style={{ top: '50%', margin: '0' }}>
                                Copyright &copy; 
                                sebastian-kollmar.com 2021
                        </p>
                            
                            <ul class="navbar-nav">
                                <li
                                class={`nav-item  ${
                                    props.location.pathname === "/" ? "active" : ""
                                    }`}
                                >
                                    <Link class="nav-link" to="/">
                                    Home
                                    <span class="sr-only">(current)</span>
                                </Link>
                                </li>
                                <li
                                    class={`nav-item  ${
                                    props.location.pathname === "/legal" ? "active" : ""
                                    }`}
                                >
                                    <Link class="nav-link" to="/legal">
                                    {content.legal}
                                    </Link>
                                </li>
                                
                            </ul>
                        </div>
                    </nav>
                </div>
            </footer>
        </div>
    );
}

export default withRouter(Footer);