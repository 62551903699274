import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import { Navigation, Footer, Header, Legal } from "./components";
import codingLogo from "./coding.png";
import biLogo from "./bi.png";
import dataLogo from "./dataLogo.png";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import  Home  from "./Home";

function App() {

    let languageStoredInLocalStorage = localStorage.getItem("language");
    let [language, setLanguage] = useState(
        languageStoredInLocalStorage ? languageStoredInLocalStorage : "English"
    );

  return (
    <div className="App">
        <Router>
            <Navigation 
                language={language}
                handleSetLanguage={(language: string) => {
                    setLanguage(language);
                    storeLanguageInLocalStorage(language);
                }}
            />
            <Header language={language} className="App-header" />
            <Switch>
                <Route path="/" exact component={() => <Home language={language} />} />
                <Route path="/legal" exact component={() => <Legal language={language} /> } />
            </Switch>
            <Footer language={language} />

        </Router>

    </div>
  );
}

function storeLanguageInLocalStorage(language: string) {
    localStorage.setItem("language", language);
}

export default App;
