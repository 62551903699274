import React from "react";
import ReactHtmlParser from "react-html-parser";

function Legal(props) {
    let content = {
        English: {
            title: "<h2>Legal notice</h2>",
            address: "Information according to german law § 5 TMG <br><br> \
                Sebastian Kollmar <br>\
                Erstellung von Software und Datenbanken <br>\
                Glücksburger Straße 37 <br>\
                44799 Bochum <br><br> \
                Represented by: Sebastian Kollmar <br><br> \
                <b>Contact:</b><br>\
                Phone: +49 1520 5348258 <br> \
                Email: info.kollmar@web.de <br><br> \
                ",

                text: "<h3>Disclaimer</h3> \
                <b> Liability for content</b> <br> \
                The contents of our pages were created with the utmost care. \
                However, we can not guarantee the accuracy, completeness and timeliness of the content. \
                As a service provider, we are responsible for our own content on these pages under the general laws according to § 7 Abs.1 TMG. \
                According to §§ 8 to 10 TMG we are not obligated to monitor transmitted or stored information or to investigate circumstances that indicate illegal activity. \
                Obligations to remove or block the use of information according to general laws remain unaffected. \
                However, liability in this regard is only possible from the time of knowledge of a concrete infringement. \
                Upon becoming aware of corresponding violations of the law, we will remove this content immediately. <br><br>\
                <h3>Liability for links</h3> \
                Our offer contains links to external websites of third parties, on whose contents we have no influence. \
                Therefore, we can not assume any liability for these external contents. \
                The respective provider or operator of the pages is always responsible for the content of the linked pages. \
                The linked pages were checked for possible legal violations at the time of linking. \
                Illegal contents were not recognizable at the time of linking. \
                However, a permanent control of the contents of the linked pages is not reasonable without concrete evidence of a violation of the law. \
                If we become aware of any infringements, we will remove such links immediately. <br><br> \
                <h3>Copyright</h3> \
                The content and works created by the site operators on these pages are subject to German copyright law. \
                The reproduction, editing, distribution and any kind of exploitation outside the limits of copyright require the written consent of the author or creator. \
                Downloads and copies of this site are only permitted for private, non-commercial use. \
                Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. \
                In particular, third-party content is identified as such. \
                Should you nevertheless become aware of a copyright infringement, please inform us accordingly. \
                If we become aware of any infringements, we will remove such content immediately. <br><br> \
                <h3>Privacy</h3> \
                The use of our website is generally possible without providing personal data. \
                Insofar as personal data (for example, name, address or e-mail addresses) are collected on our pages, this is always done, as far as possible, on a voluntary basis. \
                This data will not be passed on to third parties without your express consent. \
                We point out that data transmission over the Internet (eg communication by e-mail) security gaps. \
                Complete protection of data against access by third parties is not possible. \
                The use of contact data published within the framework of the imprint obligation by third parties for the purpose of sending unsolicited advertising and information materials is hereby expressly prohibited. \
                The operators of the pages expressly reserve the right to take legal action in the event of unsolicited sending of advertising information, such as through spam mails.<br><br><br>"
            },
        Deutsch: {
            title: "<h2>Impressum und Datenschutz</h2>",
            address: "Angaben gemäß § 5 TMG <br><br> \
                Sebastian Kollmar <br>\
                Erstellung von Software und Datenbanken <br>\
                Glücksburger Straße 37 <br>\
                44799 Bochum <br><br> \
                Vertreten durch: Sebastian Kollmar <br><br> \
                <b>Kontakt:</b><br>\
                Telefon: 01520 5348258 <br> \
                E-Mail: info.kollmar@web.de <br><br> \
                ",
            text: "<h3>Haftungsausschluss</h3> \
            <b> Haftung für Inhalte </b> <br> \
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. \
            Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. \
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. \
            Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. \
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. \
            Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. \
            Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. <br><br>\
            <h3>Haftung für Links</h3> \
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. \
            Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. \
            Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. \
            Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. \
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. \
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. \
            Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen. <br><br> \
            <h3>Urheberrecht</h3> \
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. \
            Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. \
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. \
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. \
            Insbesondere werden Inhalte Dritter als solche gekennzeichnet. \
            Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. \
            Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen. <br><br> \
            <h3>Datenschutz</h3> \
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. \
            Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. \
            Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. \
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. \
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. \
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. \
            Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.<br><br><br><br>"
        }
    };

    props.language === "Deutsch" ? (content = content.Deutsch) : (content = content.English);

    return (
        <div className="container">
        {ReactHtmlParser(content.title)}
        {ReactHtmlParser(content.address)}
            <div className="mb-5">
                {ReactHtmlParser(content.text)}
            </div>
        </div>
    );
}

export default Legal;